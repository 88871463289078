// CartManager dependencies
import { flatMap } from "lodash";
import { calculateProductPrice } from "../admin/components/poses/pos/utils";
import { isCartProductAPriceAdjust } from "../admin/components/poses/shared/utils/cartController";
import {
    getDiscountedCartTotalOriginalPrice,
    getDiscountedCartTotalAdjustedQuantities,
    getDiscountedCartTotalFromCartProducts,
    getUpdatedUnitPrice
} from "../admin/components/poses/pos/utils/priceUtils";
import { OrderWindowsStore } from "Stores";
import {
    Addon,
    BundleProductCategory,
    BundleProductItem,
    CartIdAndQuantity,
    CartProduct,
    SplitOrderWindowDiscounts,
    Discount,
    DISCOUNT_TYPE,
    FixedDiscount,
    FixedDiscounts,
    Modification,
    OnlineFixedDiscount,
    OrderProduct,
    OrderWindow,
    RefProduct,
    MenuProductHashValues,
    HashValueOrderProduct
} from "Types";
import { GetState } from "zustand";
import {
    recalculatePriceAndDiscount,
    resetCartProductUnitPrice,
    updateAllCartFixedDiscounts,
    updateCartProductUnitPrice,
    updateCombinedDiscountsAndOtherDiscounts
} from "../admin/components/poses/shared/utils/cartController";
import {
    AddonOperation,
    BundleItemOperation,
    createCartProductHashId,
    mergeOrSplitCartProductsByHashId,
    upsertAddons,
    upsertBundleItems
} from "Providers";
import { isValidUUID } from "../admin/components/poses/pos/utils/helpers";
import { findCartProduct } from "Utils/cartProductHelpers";
import { getProductCartQuantity } from "Utils/productHelpers";
export class CartManager {
    /**
     * It is necessary to pass access to the store here in order for the class to access the functions and members
     * This might not be the best solution, but since we are currently storing the CartManager instance in the store itself, we have
     * access to the GetState get() function which provides access to all available members.
     */
    get: GetState<OrderWindowsStore>;

    constructor(get: GetState<OrderWindowsStore>) {
        /**
         *  Can do a lot better on the naming here
         *
         * this.getOrderWindowStore() -- This would transform
         * const { orderWindows, setOrderWindows, activeOrderWindowId } = this.get();
         * into
         * const { orderWindows, setOrderWindows, activeOrderWindowId } = this.getOrderWindowStore();
         *
         * Which would probably be better if we end up depending on multiple stores here, e.g.
         * const { orderWindows } = this.getOrderWindowStore();
         * const { menuItems } = this.getMenuStore();
         */
        this.get = get;
    }

    // NAMING: `applyOrderWindowDiscounts`
    revaluateOrderDiscount = () => {
        const { orderWindows, setOrderWindows, activeOrderWindowId } = this.get();

        const orderWindowsWithDiscountedCartProducts = orderWindows.map((window: OrderWindow) => {
            if (window.id === activeOrderWindowId) {
                return {
                    ...window,
                    // NAMING: `calculateCartProductsWithAttachedDiscounts(window)`
                    cartProducts: updateCombinedDiscountsAndOtherDiscounts(window)
                };
            } else {
                return window;
            }
        });
        setOrderWindows(orderWindowsWithDiscountedCartProducts);
    };

    // NAMING: `updateCartProductsInActiveOrderWindow`
    //  Maybe we should not handle both CartProduct and CartProduct[] here, this makes the logic of the body much more complex
    //   Please.
    handleCartProduct = <T>(
        cartProducts: T,
        cartProductHandler: (cartProducts: T, orderWindow: OrderWindow) => CartProduct[]
    ): OrderWindow[] => {
        const { orderWindows, setOrderWindows, activeOrderWindowId, allActiveFixedDiscounts } = this.get();

        const updateOrderWindows = orderWindows.map((orderWindow: OrderWindow) => {
            if (orderWindow.id === activeOrderWindowId) {
                let updatedCartProducts = cartProductHandler(cartProducts, orderWindow);

                //extra step to filter out flag idBeforeEdit, which is not part of orderWindow class
                const updatedCartProductsToSave = updatedCartProducts.map(cart => {
                    if (cart.isFinished) {
                        const { idBeforeEdit, ...cartToSave } = cart;
                        return cartToSave;
                    }
                    return cart;
                });

                const fixedDiscountIsPresent = allActiveFixedDiscounts?.length || !!orderWindow.fixedDiscounts?.length;
                let discountedProductIds: string[] = [];
                if (!!orderWindow.discountedProductIds?.length) {
                    //build a new array of discountedProductIds
                    //is product was edited it will get a new id, so need to "replace" old ids with new in discountedProductIds
                    updatedCartProducts.forEach(cart => {
                        const editedProductHasDiscount =
                            (cart?.oldId && orderWindow.discountedProductIds.includes(cart.oldId)) ||
                            (cart?.idBeforeEdit && orderWindow.discountedProductIds.includes(cart.idBeforeEdit));
                        if (orderWindow.discountedProductIds.includes(cart.id) || editedProductHasDiscount) {
                            discountedProductIds.push(cart.id);
                        }
                    });
                }
                if (fixedDiscountIsPresent) {
                    const updatedOrderWindow = {
                        ...orderWindow,
                        cartProducts: updatedCartProductsToSave,
                        fixedDiscounts: this.onSetOrderWindowFixedDiscounts(
                            updatedCartProducts,
                            orderWindow.fixedDiscounts,
                            allActiveFixedDiscounts
                        ),
                        fixedDiscountedProductIds: updatedCartProducts
                            .filter(
                                cart =>
                                    !!cart.fixedDiscount?.fixedDiscountId && cart.fixedDiscount.isFixedDiscountActive
                            )
                            .map(cart => cart.id)
                    };
                    return {
                        ...updatedOrderWindow,
                        cartProducts: updateAllCartFixedDiscounts(updatedOrderWindow, updatedOrderWindow.fixedDiscounts)
                    };
                } else {
                    if (orderWindow.discount) {
                        const updateOrderWindow = {
                            ...orderWindow,
                            cartProducts: updatedCartProductsToSave,
                            discount:
                                orderWindow.discount && updatedCartProducts.length > 0 ? orderWindow.discount : null,
                            discountedProductIds: discountedProductIds ? discountedProductIds : []
                        };
                        return {
                            ...updateOrderWindow,
                            cartProducts: updateCombinedDiscountsAndOtherDiscounts(updateOrderWindow)
                        };
                    }

                    return {
                        ...orderWindow,
                        cartProducts: updatedCartProductsToSave,
                        discount: null,
                        discountedProductIds: []
                    };
                }
            } else {
                return orderWindow;
            }
        });
        return updateOrderWindows;
    };

    // NAMING: `getCartProductsExcludingDeleted`
    handleDeleteOrderProducts = (cartProductsToDelete: CartProduct[], orderWindow: OrderWindow) => {
        return orderWindow?.cartProducts?.filter(cartProduct =>
            cartProductsToDelete.every(cptd => cptd.id !== cartProduct.id)
        );
    };
    // NAMING: `getCartProductsExcludingDeleted`
    handleDeleteOrderProduct = (cartProductToDelete: CartProduct, orderWindow: OrderWindow) => {
        return orderWindow?.cartProducts?.filter(cartProduct => cartProduct.id !== cartProductToDelete.id);
    };
    removeCartProducts = (cartProducts: CartProduct[]) => {
        const { setOrderWindows, setSelectedBundleCartProduct, selectedBundleCartProduct } = this.get();
        if (cartProducts?.some(cartProduct => cartProduct.id === selectedBundleCartProduct?.id)) {
            setSelectedBundleCartProduct(null);
        }
        const updatedOrderWindows = this.handleCartProduct(cartProducts, this.handleDeleteOrderProducts);
        return setOrderWindows(updatedOrderWindows);
    };

    /**
     * Maybe this could be converted to a PURE function if we send in `allActiveFixedDiscounts`
     */
    isADiscountedProduct = (fixedDiscount: OnlineFixedDiscount | undefined) => {
        if (!fixedDiscount) {
            return false;
        }
        const { allActiveFixedDiscounts } = this.get();
        const { menuId, menuProductId, menuCategoryId } = fixedDiscount;

        if (fixedDiscount?.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT) {
            return true;
        }

        const discounts = flatMap<FixedDiscount>(
            allActiveFixedDiscounts?.map((discount: FixedDiscount) => discount.menuCategoryAndProductIds)
        );

        return discounts?.some((discount: FixedDiscount) => {
            if (discount.menuId !== menuId) {
                return false;
            }
            if (discount.productIds.length === 0 && discount.categoryIds.length === 0) {
                return true;
            }
            const categoryHasFixedDiscount = menuCategoryId && discount.categoryIds?.includes(menuCategoryId);
            const productHasFixedDiscount = menuProductId && discount.productIds?.includes(menuProductId);
            return categoryHasFixedDiscount || productHasFixedDiscount;
        });
    };

    // NAMING: `updateCartProductsInOrderWindow`
    setCartProductsToOrderWindow = (
        cartProducts: CartProduct[],
        splitWindowDiscounts: SplitOrderWindowDiscounts | null = null
    ) => {
        const { orderWindows, activeOrderWindowId, setOrderWindows } = this.get();
        const updatedOrderWindows = orderWindows.map((orderWindow: OrderWindow) => {
            if (orderWindow.id === activeOrderWindowId) {
                if (splitWindowDiscounts) {
                    const updatedOrderWindow = {
                        ...orderWindow,
                        ...splitWindowDiscounts,
                        cartProducts
                    };
                    return {
                        ...updatedOrderWindow,
                        cartProducts: updateCombinedDiscountsAndOtherDiscounts(updatedOrderWindow)
                    };
                }
                return {
                    ...orderWindow,
                    cartProducts
                };
            } else {
                return orderWindow;
            }
        });
        setOrderWindows(updatedOrderWindows);
    };

    updateBundleCartProductWithAddonsOrComment = (
        bundleCartProduct: CartProduct,
        quantity: number,
        fixedDiscount: OnlineFixedDiscount | undefined,
        comment: string | null | undefined,
        addons: Addon[],
        isEditing: boolean = true
    ) => {
        const { getActiveOrderWindow, activeOrderWindowId } = this.get();
        const activeOrderWindow = getActiveOrderWindow();
        const ProductStockManager = this.get().ProductStockManager;
        const cartTotalOriginalPrice = getDiscountedCartTotalOriginalPrice(activeOrderWindow);

        const hashValueExtra = !!fixedDiscount ? { type: fixedDiscount.type } : null;

        const isLastOrderProduct = !!bundleCartProduct.orderProduct?.isLastOrderProduct
            ? bundleCartProduct.orderProduct?.isLastOrderProduct
            : false;

        const bundleItemToHash = {
            ...bundleCartProduct,
            isFinished: true,
            fixedDiscount,
            orderProduct: {
                ...bundleCartProduct.orderProduct,
                addons: addons,
                comment: comment
            }
        } as CartProduct;

        const newBundleCartProductId = createCartProductHashId(bundleItemToHash, activeOrderWindowId!, hashValueExtra);

        const hasBundleCartProductIdChanged = bundleCartProduct.id != newBundleCartProductId;

        const cartProductExists = isEditing
            ? bundleCartProduct
            : findCartProduct(activeOrderWindow, newBundleCartProductId);
        const newQuantity = isEditing
            ? Number(quantity)
            : Number(getProductCartQuantity(quantity, cartProductExists, false));
        const hasUpdatedUnitPrice = !!cartProductExists?.updatedUnitPrice;

        const pricesAndVats = calculateProductPrice(
            bundleCartProduct.menuBundleProduct,
            newQuantity,
            null,
            bundleCartProduct.orderProduct.selectedBundleProductItems,
            addons,
            hasUpdatedUnitPrice ? cartProductExists.orderProduct.unitPrice : null
        );

        let updatedBundleCartProduct = {
            ...bundleCartProduct,
            isFinished: true,
            id: newBundleCartProductId,
            // We only want to update the oldId if the new id has changed
            oldId: hasBundleCartProductIdChanged ? bundleCartProduct.id : bundleCartProduct.oldId,
            fixedDiscount,
            orderProduct: {
                ...bundleCartProduct.orderProduct,
                ...pricesAndVats,
                addons: addons,
                quantity: newQuantity,
                comment,
                isLastOrderProduct
            }
        } as CartProduct;

        if (activeOrderWindow?.discount) {
            const updatedCartOriginalPrice = cartTotalOriginalPrice + updatedBundleCartProduct.orderProduct.unitPrice;
            updatedBundleCartProduct = recalculatePriceAndDiscount(
                updatedBundleCartProduct,
                activeOrderWindow.discount,
                activeOrderWindow.discountedProductIds,
                updatedCartOriginalPrice,
                activeOrderWindow?.discountedIdsAndQuantity ?? null
            ) as CartProduct;
        }
        // Check stock status
        if (bundleCartProduct.orderProduct.isStockTracked) {
            ProductStockManager.onUpdateRemainingStockQuantity(updatedBundleCartProduct);
        }

        return updatedBundleCartProduct;
    };

    updateBundleCartProductWithSelectedBundleItems = (
        bundleCartProduct: CartProduct | undefined,
        refProdToAdd: RefProduct,
        bundleCategory: BundleProductCategory,
        selectedModifications = null,
        allowItemToggle = false,
        addon = null,
        bundleItemOperation: BundleItemOperation,
        bundleItemIndex?: number
    ): CartProduct | undefined => {
        const { getActiveOrderWindow, activeOrderWindowId } = this.get();
        const orderWindow = getActiveOrderWindow();

        const updatedBundleItems = upsertBundleItems(
            refProdToAdd,
            bundleCategory,
            selectedModifications,
            addon,
            bundleItemOperation,
            bundleCartProduct,
            bundleItemIndex
        );

        const bundleIsComplete =
            updatedBundleItems?.every((bundleItem: any) => !!bundleItem.refProductId) && !allowItemToggle;

        const menuBundleProductToAdd = {
            menuBundleProduct: bundleCartProduct?.menuBundleProduct,
            orderProduct: {
                selectedBundleProductItems: updatedBundleItems,
                comment: bundleCartProduct?.orderProduct.comment,
                addons: bundleCartProduct?.orderProduct.addons
            },
            customerMeta: bundleCartProduct?.customerMeta
        };

        const newCartProductHashId = bundleIsComplete
            ? createCartProductHashId(menuBundleProductToAdd, activeOrderWindowId!)
            : bundleCartProduct?.id;

        const quantity = bundleCartProduct?.orderProduct.quantity;
        const addons = bundleCartProduct?.orderProduct.addons.length ? bundleCartProduct?.orderProduct.addons : null;
        const priceAndVats = calculateProductPrice(
            bundleCartProduct?.menuBundleProduct,
            quantity,
            null,
            updatedBundleItems,
            addons
        );

        let updatedCartProduct = {
            ...bundleCartProduct,
            isFinished: bundleIsComplete,
            id: newCartProductHashId,
            oldId: bundleCartProduct?.id,
            orderProduct: {
                ...bundleCartProduct?.orderProduct,
                ...priceAndVats,
                selectedBundleProductItems: updatedBundleItems
            }
        } as CartProduct;

        const updateDiscountProductQuantities = this.updateAppliedDiscountQuantities(
            newCartProductHashId,
            updatedCartProduct.oldId
        );
        const updateDiscountProductIds = this.updateDiscountedProductIds(
            newCartProductHashId,
            updatedCartProduct.oldId
        );

        if (orderWindow?.discount) {
            const updatedCartProducts = orderWindow?.cartProducts?.map(cart => {
                return cart.id === updatedCartProduct.oldId ? updatedCartProduct : cart;
            });
            const calculatedNewCartValue = getDiscountedCartTotalFromCartProducts(
                updatedCartProducts,
                orderWindow.discount,
                updateDiscountProductIds,
                updateDiscountProductQuantities
            );

            const discountQuantities = !!updateDiscountProductQuantities
                ? (updateDiscountProductQuantities as unknown as CartIdAndQuantity[])
                : null;

            updatedCartProduct = recalculatePriceAndDiscount(
                updatedCartProduct,
                orderWindow.discount,
                updateDiscountProductIds,
                calculatedNewCartValue,
                discountQuantities
            ) as CartProduct;
        }

        return updatedCartProduct;
    };

    setBundleProductToFinished = (cartProduct: CartProduct, comment: string, shouldAddAsNew: boolean = false) => {
        const { fixedDiscount } = cartProduct;
        const { activeOrderWindowId, setOrderWindows, setSelectedBundleCartProduct, getActiveOrderWindow } = this.get();
        const orderWindow = getActiveOrderWindow();

        const menuBundleProduct = {
            ...cartProduct,
            orderProduct: {
                ...cartProduct.orderProduct,
                comment: comment
            }
        } as unknown as HashValueOrderProduct;

        const hashValueExtra = !!fixedDiscount ? { type: fixedDiscount.type } : null;
        const updatedHash = createCartProductHashId(menuBundleProduct, activeOrderWindowId!, hashValueExtra);

        const updatedBundleProduct = {
            ...cartProduct,
            isFinished: true,
            id: updatedHash,
            oldId: cartProduct.id,
            orderProduct: {
                ...cartProduct.orderProduct,
                comment
            }
        };

        const cartProductExists = !!orderWindow?.cartProducts.find(
            cartProduct => cartProduct.id === updatedBundleProduct.id && cartProduct.isFinished
        );

        if (cartProductExists) {
            setOrderWindows(this.handleCartProduct(updatedBundleProduct, this.handleIncrementOrderProductQuantity));
        } else if (!isValidUUID(cartProduct.id) || !cartProductExists) {
            const handler = shouldAddAsNew ? this.handleAddNewCartProduct : this.handleUpdateCartProduct;

            setOrderWindows(this.handleCartProduct(updatedBundleProduct, handler));
        }

        setSelectedBundleCartProduct(null);
    };

    addBundleItemToBundleProduct = (
        refProdToAdd: RefProduct,
        bundleCategory: BundleProductCategory,
        selectedModifications = null,
        allowItemToggle = false,
        addon = null,
        bundleItemOperation: BundleItemOperation,
        bundleItemIndex: number
    ) => {
        const {
            activeOrderWindowId,
            getActiveOrderWindow,
            setOrderWindows,
            selectedBundleCartProduct,
            setSelectedBundleCartProduct
        } = this.get();
        const orderWindow = getActiveOrderWindow();

        const bundleCartProduct = selectedBundleCartProduct!;

        const updatedBundleCartProduct = this.updateBundleCartProductWithSelectedBundleItems(
            bundleCartProduct,
            refProdToAdd,
            bundleCategory,
            selectedModifications,
            allowItemToggle,
            addon,
            bundleItemOperation,
            bundleItemIndex
        );

        if (!updatedBundleCartProduct) {
            return;
        }

        const bundleIsComplete = updatedBundleCartProduct.isFinished && !allowItemToggle;
        const cartProductId = updatedBundleCartProduct.id;

        const updateDiscountProductQuantities = this.updateAppliedDiscountQuantities(
            cartProductId,
            updatedBundleCartProduct.oldId
        );
        const updateDiscountProductIds = this.updateDiscountedProductIds(cartProductId, updatedBundleCartProduct.oldId);

        let updatedOrderWindows = [];
        if (bundleIsComplete) {
            const isEditingFinishedProduct = !isValidUUID(cartProductId);
            const cartProductExists = !!orderWindow?.cartProducts.find(cartProduct => cartProduct.id === cartProductId);
            updatedBundleCartProduct.isFinished;

            const sameIdAsBefore = cartProductId === updatedBundleCartProduct.oldId;
            const shouldIncrement = cartProductExists && isEditingFinishedProduct && !sameIdAsBefore;
            if (updatedBundleCartProduct.isFinished && sameIdAsBefore && updatedBundleCartProduct.idBeforeEdit) {
                updatedBundleCartProduct.oldId = updatedBundleCartProduct.idBeforeEdit;
            }
            updatedOrderWindows = shouldIncrement
                ? this.handleCartProduct(updatedBundleCartProduct, this.handleIncrementOrderProductQuantity)
                : this.handleCartProduct(updatedBundleCartProduct, this.handleUpdateCartProduct);
        } else {
            updatedOrderWindows = this.handleCartProduct(updatedBundleCartProduct, this.handleUpdateCartProduct);
        }

        if (updateDiscountProductQuantities && updateDiscountProductQuantities.length > 0) {
            updatedOrderWindows = updatedOrderWindows.map(window => {
                if (window.id === activeOrderWindowId) {
                    const updateOrderWindow = {
                        ...window,
                        discountedIdsAndQuantity: updateDiscountProductQuantities,
                        discountedProductIds: updateDiscountProductIds
                    };

                    if (window.discount && window.discount?.amount > 0) {
                        return {
                            ...updateOrderWindow,
                            cartProducts: updateCombinedDiscountsAndOtherDiscounts(
                                updateOrderWindow as unknown as OrderWindow
                            )
                        };
                    } else {
                        return updateOrderWindow;
                    }
                } else {
                    return window;
                }
            }) as OrderWindow[];
        }

        // To update selected bundle cart product
        setSelectedBundleCartProduct(updatedBundleCartProduct);
        setOrderWindows(updatedOrderWindows);

        if (bundleIsComplete) {
            // continue work with bundle product with its new id
            setSelectedBundleCartProduct(allowItemToggle ? updatedBundleCartProduct : null);
        }
    };

    updateBundleItemWithModification = (
        bundleCartProduct: CartProduct,
        bundleItemIndex: number,
        modification: Modification
    ) => {
        const updatedSelectedBundleItems = bundleCartProduct?.orderProduct?.selectedBundleProductItems?.map(
            (selectedBundleProductItem, index) => {
                if (index === bundleItemIndex) {
                    return {
                        ...selectedBundleProductItem,
                        modifications: {
                            ...selectedBundleProductItem.modifications,
                            ...modification
                        }
                    };
                } else {
                    return selectedBundleProductItem;
                }
            }
        );

        const updatedBundleCartProduct = {
            ...bundleCartProduct,
            orderProduct: {
                ...bundleCartProduct.orderProduct,
                selectedBundleProductItems: updatedSelectedBundleItems
            }
        } as CartProduct;
        return updatedBundleCartProduct;
    };

    // Used in online only currently. Will update bundleItem's modification object.
    // Instead of having internal state of which selected modifications, we always update orderWindows
    addModificationToSelectedBundleItem = (
        bundleCartProduct: CartProduct,
        bundleItemIndex: number,
        modification: Modification
    ) => {
        const updatedBundleCartProduct = this.updateBundleItemWithModification(
            bundleCartProduct,
            bundleItemIndex,
            modification
        );
        this.get().setOrderWindows(this.handleCartProduct(updatedBundleCartProduct, this.handleUpdateCartProduct));
    };

    addManyBundleItemsToBundleProduct = (bundleItems: BundleProductItem[]) => {
        const {
            orderWindows,
            activeOrderWindowId,
            setOrderWindows,
            selectedBundleCartProduct,
            setSelectedBundleCartProduct
        } = this.get();
        const orderWindow = orderWindows.find((orderWindow: OrderWindow) => orderWindow.id === activeOrderWindowId);

        const isBundleComplete = bundleItems.every(bundleItem => !!bundleItem.refProductId);

        const menuBundleProductToAdd: MenuProductHashValues = {
            menuBundleProduct: selectedBundleCartProduct?.menuBundleProduct,
            orderProduct: {
                selectedBundleProductItems: bundleItems
            }
        };

        const cartProductId = isBundleComplete
            ? createCartProductHashId(menuBundleProductToAdd, activeOrderWindowId!)
            : selectedBundleCartProduct?.id;

        const quantity = selectedBundleCartProduct?.orderProduct.quantity;
        const priceAndVats = calculateProductPrice(
            selectedBundleCartProduct?.menuBundleProduct,
            quantity,
            null,
            bundleItems,
            null
        );

        let updatedBundleCartProduct = {
            ...selectedBundleCartProduct,
            isFinished: isBundleComplete,
            id: selectedBundleCartProduct?.id,
            oldId: selectedBundleCartProduct?.id,
            orderProduct: {
                ...selectedBundleCartProduct?.orderProduct,
                ...priceAndVats,
                selectedBundleProductItems: bundleItems
            }
        } as CartProduct;

        let updatedOrderWindows = [];
        if (isBundleComplete) {
            const isEditingFinishedProduct = !isValidUUID(updatedBundleCartProduct.id);
            const cartProductExists = !!orderWindow?.cartProducts.find(cartProduct => cartProduct.id === cartProductId);
            updatedOrderWindows =
                cartProductExists && !isEditingFinishedProduct
                    ? this.handleCartProduct(updatedBundleCartProduct, this.handleIncrementOrderProductQuantity)
                    : this.handleCartProduct(updatedBundleCartProduct, this.handleUpdateCartProduct);
        } else {
            updatedOrderWindows = this.handleCartProduct(updatedBundleCartProduct, this.handleUpdateCartProduct);
        }

        setOrderWindows(updatedOrderWindows);

        setSelectedBundleCartProduct(updatedBundleCartProduct);
    };

    onHandleFixedDiscount = () => {
        const { orderWindows, setOrderWindows, activeOrderWindowId, allActiveFixedDiscounts } = this.get();

        const updateOrderWindows = orderWindows.map((orderWindow: OrderWindow) => {
            if (orderWindow.id === activeOrderWindowId) {
                const updatedProducts = this.onHandleCartProductsFixedDiscounts(orderWindow?.cartProducts);
                const hasFixedDiscounts = allActiveFixedDiscounts?.length > 0;
                if (hasFixedDiscounts) {
                    const fixedDiscountedProductIds =
                        updatedProducts
                            .filter((cart: CartProduct) => cart?.fixedDiscount?.isFixedDiscountActive)
                            .map(cart => cart.id) ?? [];

                    const { discount, discountedProductIds, ...noDiscountsOrderWindow } = orderWindow;
                    const updatedOrderWindow = {
                        ...noDiscountsOrderWindow,
                        fixedDiscounts: this.onSetOrderWindowFixedDiscounts(
                            updatedProducts,
                            orderWindow.fixedDiscounts,
                            allActiveFixedDiscounts
                        ),
                        cartProducts: updatedProducts,
                        fixedDiscountedProductIds
                    } as unknown as OrderWindow;
                    return {
                        ...updatedOrderWindow,
                        cartProducts: updateAllCartFixedDiscounts(updatedOrderWindow, allActiveFixedDiscounts)
                    };
                } else {
                    const updatedOrderWindow = {
                        ...orderWindow,
                        discount: null,
                        cartProducts: updatedProducts,
                        fixedDiscounts: undefined,
                        fixedDiscountedProductIds: []
                    } as OrderWindow;
                    return {
                        ...updatedOrderWindow,
                        cartProducts: updateAllCartFixedDiscounts(updatedOrderWindow, allActiveFixedDiscounts)
                    };
                }
            } else {
                return orderWindow;
            }
        }) as OrderWindow[];

        setOrderWindows(updateOrderWindows);
    };

    setActiveOrderWindowDiscount = (discount: Discount, discountedProductIds: string[]) => {
        const { orderWindows, setOrderWindows, activeOrderWindowId, allActiveFixedDiscounts } = this.get();
        const updatedOrderWindows = orderWindows.map(window => {
            if (window.id === activeOrderWindowId) {
                const updatedOrderWindow = {
                    ...window,
                    discount,
                    discountedProductIds
                };
                return {
                    ...updatedOrderWindow,
                    cartProducts: updateCombinedDiscountsAndOtherDiscounts(updatedOrderWindow)
                };
            } else {
                return window;
            }
        });
        setOrderWindows(updatedOrderWindows);
    };

    setActiveDiscountWithQuantities = (
        discount: Discount,
        discountedProductIds: string[],
        discountedIdsAndQuantity: CartIdAndQuantity[]
    ) => {
        const { orderWindows, setOrderWindows, activeOrderWindowId, allActiveFixedDiscounts } = this.get();
        const updatedOrderWindows = orderWindows.map(window => {
            if (window.id === activeOrderWindowId) {
                const updatedOrderWindow = {
                    ...window,
                    discount,
                    discountedProductIds,
                    discountedIdsAndQuantity
                };
                return {
                    ...updatedOrderWindow,
                    cartProducts: updateCombinedDiscountsAndOtherDiscounts(updatedOrderWindow)
                };
            } else {
                return window;
            }
        });
        setOrderWindows(updatedOrderWindows);
    };

    updateUnitPrice = (updatedUnitPrice: number, productIds: string[]) => {
        const { orderWindows, setOrderWindows, activeOrderWindowId, allActiveFixedDiscounts } = this.get();
        const updatedOrderWindows = orderWindows.map(window => {
            if (window.id === activeOrderWindowId) {
                return {
                    ...window,
                    cartProducts: updateCartProductUnitPrice(window.cartProducts, updatedUnitPrice, productIds)
                };
            } else {
                return window;
            }
        });
        setOrderWindows(updatedOrderWindows);
    };

    resetUnitPrice = (cartProductId: string) => {
        const { orderWindows, setOrderWindows, activeOrderWindowId, allActiveFixedDiscounts } = this.get();
        const updatedOrderWindows = orderWindows.map(window => {
            if (window.id === activeOrderWindowId) {
                return {
                    ...window,
                    cartProducts: resetCartProductUnitPrice(cartProductId, window.cartProducts)
                };
            } else {
                return window;
            }
        });
        setOrderWindows(updatedOrderWindows);
    };

    splitOrderWindowCartProducts = () => {
        const { orderWindows, setOrderWindows, activeOrderWindowId } = this.get();
        const updatedOrderWindows = orderWindows.map(window => {
            if (window.id === activeOrderWindowId) {
                const adjustedOrderWindow = mergeOrSplitCartProductsByHashId(window);
                return {
                    ...adjustedOrderWindow,
                    cartProducts: updateCombinedDiscountsAndOtherDiscounts(adjustedOrderWindow)
                };
            } else {
                return window;
            }
        });
        setOrderWindows(updatedOrderWindows);
    };

    updateBundleItemWithAddon = (
        bundleCartProduct: CartProduct,
        addon: Addon,
        bundleItemIndex: number,
        operation: AddonOperation
    ): CartProduct => {
        const updatedBundleItems = bundleCartProduct?.orderProduct?.selectedBundleProductItems?.map(
            (bundleItem, index) => {
                if (index === bundleItemIndex) {
                    return {
                        ...bundleItem,
                        addons: upsertAddons(bundleItem.addons, addon, operation)
                    };
                }
                return bundleItem;
            }
        );
        const updatedCartProduct = {
            ...bundleCartProduct,
            orderProduct: {
                ...bundleCartProduct?.orderProduct,
                selectedBundleProductItems: updatedBundleItems
            }
        } as CartProduct;

        return updatedCartProduct;
    };

    addAddonToBundleItem = (addon: Addon, bundleItemIndex: number, operation: AddonOperation) => {
        const { setOrderWindows, getActiveOrderWindow, selectedBundleCartProduct } = this.get();
        const activeOrderWindow = getActiveOrderWindow();
        const bundleCartProduct = activeOrderWindow?.cartProducts.find(
            cartProduct => cartProduct.id === selectedBundleCartProduct?.id
        );
        if (!bundleCartProduct) {
            return;
        }

        const updatedCartProduct = this.updateBundleItemWithAddon(bundleCartProduct, addon, bundleItemIndex, operation);

        setOrderWindows(this.handleCartProduct(updatedCartProduct, this.handleUpdateCartProduct));
    };

    getCurrentCartProducts = () => {
        const { getActiveOrderWindow } = this.get();
        const activeOrderWindow = getActiveOrderWindow();
        if (activeOrderWindow) {
            return activeOrderWindow?.cartProducts;
        } else {
            return [];
        }
    };

    getCartProduct = (cartProductId: String) => {
        const { getActiveOrderWindow } = this.get();
        const activeOrderWindow = getActiveOrderWindow();
        return activeOrderWindow?.cartProducts.find(
            cartProduct => cartProduct.id === cartProductId || cartProduct.oldId === cartProductId
        );
    };

    /**
     * PURE UTILITY FUNCTIONS
     * Can be extracted and tested later, no need to be in this class.
     */

    resetFixedDiscount = (cartProduct: CartProduct) => {
        return {
            ...cartProduct,
            fixedDiscount: {
                ...cartProduct?.fixedDiscount,
                ...{
                    fixedDiscountId: ""
                },
                isFixedDiscountActive:
                    cartProduct?.fixedDiscount?.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT ? true : false,
                canCombineDiscounts: true
            }
        };
    };
    onSetOrderWindowFixedDiscounts = (
        cartProducts: CartProduct[],
        orderWindowFixedDiscounts: FixedDiscounts | undefined,
        allActiveFixedDiscounts: FixedDiscounts
    ) => {
        // const fixedDiscounts = cartProducts.reduce((fixedDiscountIds: string[], cartProduct: CartProduct) => {
        //     if (cartProduct.fixedDiscount?.isFixedDiscountActive) {
        //         const fixedDiscountId = cartProduct.fixedDiscount.fixedDiscountId;
        //         return !fixedDiscountIds.includes(fixedDiscountId)
        //             ? [...fixedDiscountIds, fixedDiscountId]
        //             : fixedDiscountIds;
        //     }
        //     return fixedDiscountIds;
        // }, []);

        // Couldn't understand and type the above reduce, this is the replace code, sorry if we broke something. <3
        const fixedDiscountIds = cartProducts
            .map(cartProduct => cartProduct.fixedDiscount?.fixedDiscountId)
            .filter(fixedDiscountId => !!fixedDiscountId);

        const fixedDiscounts = cartProducts
            .filter(cartProduct => fixedDiscountIds.includes(cartProduct.fixedDiscount?.fixedDiscountId))
            .map(cartProduct => cartProduct.fixedDiscount?.fixedDiscountId);

        if (!!allActiveFixedDiscounts?.length) {
            return allActiveFixedDiscounts?.filter(discount => fixedDiscounts.includes(discount.id));
        } else {
            return orderWindowFixedDiscounts?.filter(discount => fixedDiscounts.includes(discount.id));
        }
    };

    onHandleCartProductsFixedDiscounts = (cartProducts: CartProduct[]) => {
        const { allActiveFixedDiscounts } = this.get();
        if (!cartProducts || !allActiveFixedDiscounts) {
            return [];
        }

        let updatedCartProducts = cartProducts.reduce<CartProduct[]>(
            (allCartProducts: CartProduct[], cartProduct: CartProduct) => {
                if (cartProduct?.fixedDiscount) {
                    const menuId = cartProduct.fixedDiscount?.menuId;
                    const subscriptionProductMeta = cartProduct.fixedDiscount?.subscriptionProductMeta;
                    let updatedCartProduct;

                    const findMenuInDiscount = allActiveFixedDiscounts?.find((discount: FixedDiscount) => {
                        if (discount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT && subscriptionProductMeta) {
                            const { amount, rate } = discount;
                            const hasMatchingAmount = subscriptionProductMeta?.amountDiscount === amount;
                            const hasMatchingRate = subscriptionProductMeta?.percentageDiscount === rate;

                            return hasMatchingAmount || hasMatchingRate;
                        } else {
                            const hasMatchingIds = discount.menuCategoryAndProductIds.some(
                                menu => menu.menuId == menuId
                            );
                            return hasMatchingIds;
                        }
                    });

                    if (findMenuInDiscount) {
                        const productIsDiscounted = this.isADiscountedProduct(cartProduct?.fixedDiscount);
                        updatedCartProduct = {
                            ...cartProduct,
                            fixedDiscount: {
                                ...cartProduct?.fixedDiscount,
                                ...{ fixedDiscountId: findMenuInDiscount.id },
                                isFixedDiscountActive: productIsDiscounted,
                                canCombineDiscounts: productIsDiscounted
                                    ? findMenuInDiscount.combineWithOtherDiscounts
                                    : true
                            }
                        } as CartProduct;
                    } else {
                        updatedCartProduct = this.resetFixedDiscount(cartProduct) as CartProduct;
                    }
                    return updatedCartProduct ? [...allCartProducts, updatedCartProduct] : allCartProducts;
                }
                return [...allCartProducts, cartProduct];
            },
            []
        );
        return updatedCartProducts;
    };

    updateAppliedDiscountQuantities = (newId: string | undefined, oldId: string | undefined) => {
        const { getActiveOrderWindow } = this.get();
        const activeOrderWindow = getActiveOrderWindow();
        const discountedIdsAndQuantity = activeOrderWindow?.discountedIdsAndQuantity;
        if (discountedIdsAndQuantity && discountedIdsAndQuantity.length > 0) {
            const updatedDiscountIdsAndQuantity = discountedIdsAndQuantity.map(idAndQuantity => {
                return {
                    ...idAndQuantity,
                    cartId: idAndQuantity.cartId === oldId ? newId : idAndQuantity.cartId
                };
            });
            return updatedDiscountIdsAndQuantity;
        } else {
            return [];
        }
    };

    updateDiscountedProductIds = (newId: string | undefined, oldId: string | undefined) => {
        const { getActiveOrderWindow } = this.get();
        const activeOrderWindow = getActiveOrderWindow();
        const discountedProductIds = activeOrderWindow?.discountedProductIds;
        if (discountedProductIds && discountedProductIds.length > 0) {
            const updatedDiscountedProductIds = discountedProductIds.map(id => (id === oldId ? newId : id));
            return updatedDiscountedProductIds;
        } else {
            return [];
        }
    };

    updateFixedDiscountedProductIds = (newId: string | undefined, oldId: string | undefined) => {
        const { getActiveOrderWindow } = this.get();
        const activeOrderWindow = getActiveOrderWindow();
        const fixedDiscountedProductIds = activeOrderWindow?.fixedDiscountedProductIds;
        if (fixedDiscountedProductIds && fixedDiscountedProductIds.length > 0) {
            const updatedDiscountedProductIds = fixedDiscountedProductIds.map(id => (id === oldId ? newId : id));
            return updatedDiscountedProductIds;
        } else {
            return [];
        }
    };

    handleIncrementOrderProductQuantity = (
        cartProductToIncrement: CartProduct,
        orderWindow: OrderWindow
    ): CartProduct[] => {
        let updatedOrderWindow = { ...orderWindow };

        // if bundle product is finished, remove the old copy
        // should be here, else updating combo products will not work properly
        if (cartProductToIncrement.menuBundleProduct && cartProductToIncrement.isFinished) {
            updatedOrderWindow = {
                ...orderWindow,
                cartProducts: updatedOrderWindow?.cartProducts?.filter(
                    cartProduct => cartProduct.id !== cartProductToIncrement.oldId
                )
            };
        }

        return updatedOrderWindow?.cartProducts?.map(cartProduct => {
            if (cartProduct.id === cartProductToIncrement.id) {
                return this.handleIncrementDecrementPriceUpdates(orderWindow, cartProduct, 1);
            } else {
                return cartProduct;
            }
        }) as CartProduct[];
    };

    handleDecrementOrderProductQuantity = (cartProductToIncrement: CartProduct, orderWindow: OrderWindow) => {
        return orderWindow?.cartProducts?.map(cartProduct => {
            if (cartProduct.id === cartProductToIncrement.id) {
                return this.handleIncrementDecrementPriceUpdates(orderWindow, cartProduct, -1);
            } else {
                return cartProduct;
            }
        });
    };

    handleIncrementDecrementPriceUpdates = (orderWindow: OrderWindow, cartProduct: CartProduct, operator: number) => {
        const newQuantity = cartProduct.orderProduct.quantity + operator;

        const updatedPriceAndVats = calculateProductPrice(
            cartProduct.menuProduct || cartProduct.menuBundleProduct,
            newQuantity,
            cartProduct.orderProduct.modifications,
            cartProduct.orderProduct.selectedBundleProductItems,
            cartProduct.orderProduct.addons,
            cartProduct.updatedUnitPrice ? cartProduct.orderProduct.unitPrice : null
        );
        let updatedCartProduct = {
            ...cartProduct,
            orderProduct: {
                ...cartProduct.orderProduct,
                quantity: newQuantity,
                ...updatedPriceAndVats
            }
        };
        if (orderWindow.discount && !orderWindow?.fixedDiscounts?.length) {
            const contiansCartId =
                orderWindow.discountedIdsAndQuantity?.some(cartQuan => cartQuan.cartId === cartProduct.id) ?? false;
            if (contiansCartId) {
                const { discountedIdsAndQuantity } = orderWindow;
                const getDiscountedCart = discountedIdsAndQuantity?.find(
                    cartQuantity => cartQuantity.cartId === cartProduct.id
                );

                if (getDiscountedCart && newQuantity < (getDiscountedCart?.quantity || 0)) {
                    getDiscountedCart.quantity = newQuantity;
                }

                orderWindow.discountedIdsAndQuantity = discountedIdsAndQuantity?.map(discount =>
                    discount.cartId === getDiscountedCart?.cartId ? getDiscountedCart : discount
                );
                const cartOriginalPrice = getDiscountedCartTotalAdjustedQuantities(
                    orderWindow,
                    orderWindow.discountedIdsAndQuantity
                );

                updatedCartProduct = recalculatePriceAndDiscount(
                    updatedCartProduct,
                    orderWindow.discount,
                    orderWindow.discountedProductIds,
                    cartOriginalPrice,
                    orderWindow.discountedIdsAndQuantity
                ) as CartProduct;
            } else {
                const cartOriginalPrice =
                    getDiscountedCartTotalOriginalPrice(orderWindow) +
                    updatedCartProduct.orderProduct.unitPrice * operator;
                updatedCartProduct = recalculatePriceAndDiscount(
                    updatedCartProduct,
                    orderWindow.discount,
                    orderWindow.discountedProductIds,
                    cartOriginalPrice,
                    null
                ) as CartProduct;
            }
        } else {
            const isPriceAdjust = isCartProductAPriceAdjust(updatedCartProduct);
            if (updatedCartProduct?.updatedUnitPrice && isPriceAdjust) {
                const { orderProduct } = cartProduct;

                // NAMING : `calculateCombinedDiscount(cartProduct)`
                // WARNING (experimental): `getUpdatedUnitPrice` can return any number of OrderProduct fields, but not the entire OrderProduct
                //   COULD EXPLODE AT ANY MOMENT!!!
                const { combinedDiscounts } = getUpdatedUnitPrice(
                    orderProduct.unitPrice,
                    updatedCartProduct
                ) as OrderProduct;
                updatedCartProduct = {
                    ...updatedCartProduct,
                    orderProduct: {
                        ...updatedCartProduct.orderProduct,
                        combinedDiscounts: combinedDiscounts
                    }
                };
            }
        }
        return updatedCartProduct;
    };

    handleUpdateCartProduct = (cartProductToUpdate: CartProduct, orderWindow: OrderWindow) => {
        return orderWindow?.cartProducts?.map(cartProduct =>
            cartProduct.id === cartProductToUpdate.id || cartProduct.id === cartProductToUpdate.oldId
                ? cartProductToUpdate
                : cartProduct
        );
    };

    // handleUpdateCartProduct = (cartProductToUpdate: CartProduct, orderWindow: OrderWindow): CartProduct[] => {
    //     console.log("orderWindow?.cartProducts", orderWindow?.cartProducts);
    //     const menuId = cartProductToUpdate?.menuBundleProduct?.id;
    //     console.log("menuId", menuId);
    //     // const updatedCartProductHash = hash({ id: menuId });
    //     const updatedCartProductHash = hash({
    //         // id: cartProductToUpdate.menuBundleProduct?.id,
    //         selectedBundleItems: cartProductToUpdate?.orderProduct.selectedBundleProductItems
    //     });

    //     console.log("updatedCartProductHash", updatedCartProductHash);
    //     const matchingCartProductToIncrement = orderWindow?.cartProducts?.find(existingCartProduct => {
    //         // console.log("existingCartProduct", existingCartProduct);
    //         const menuId2 = existingCartProduct?.menuBundleProduct?.id;
    //         console.log("menuId2", menuId2);
    //         // const existingCartProductHash = hash({ id: menuId2 });
    //         const existingCartProductHash = hash({
    //             // id: cartProductToUpdate.menuBundleProduct?.id,
    //             selectedBundleItems: existingCartProduct?.orderProduct.selectedBundleProductItems
    //         });
    //         // const existingCartProductHash = hash(
    //         //     existingCartProduct?.menuBundleProduct?.id,
    //         //     "existingCartProduct?.orderProduct.selectedBundleProductItems",
    //         //     "existingCartProduct?.orderProduct.comment",
    //         //     "existingCartProduct?.orderProduct.addons",
    //         //     "existingCartProduct?.orderProduct.discountValue",
    //         //     "orderWindow?.id"
    //         // );
    //         console.log("existingCartProductHash", existingCartProductHash);
    //         return !existingCartProduct.isFinished || existingCartProduct.id == cartProductToUpdate.id;
    //         // return existingCartProduct.id === cartProductToUpdate.id;
    //     });

    //     console.log("matchingCartProductToIncrement", matchingCartProductToIncrement);

    //     if (matchingCartProductToIncrement) {
    //         // CASE 2: incrementing product quantity when  bundle content is similar
    //         return this.handleIncrementOrderProductQuantity(matchingCartProductToIncrement, orderWindow);
    //     }

    //     // Calculate updated cartProducts if matching cartProduct exists and we want to update the selectedBundleItems
    //     let hasUpdatedCartProduct = false;
    //     const updatedCartProducts = orderWindow?.cartProducts?.map(existingCartProduct => {
    //         const existingBundleIsComplete = existingCartProduct?.orderProduct.selectedBundleProductItems?.every(
    //             bundleItem => !!bundleItem.refProductId
    //         );
    //         const existingCartProductHash = getMenuBundleProductHash(
    //             existingCartProduct?.menuBundleProduct?.id,
    //             null,
    //             existingCartProduct?.orderProduct.comment,
    //             existingCartProduct?.orderProduct.addons,
    //             existingCartProduct?.orderProduct.discountValue,
    //             orderWindow?.id
    //         );

    //         const updatedBundleIsComplete = cartProductToUpdate?.orderProduct.selectedBundleProductItems?.every(
    //             bundleItem => !!bundleItem.refProductId
    //         );
    //         const updatedCartProductHash = getMenuBundleProductHash(
    //             cartProductToUpdate?.menuBundleProduct?.id,
    //             null,
    //             cartProductToUpdate?.orderProduct.comment,
    //             cartProductToUpdate?.orderProduct.addons,
    //             cartProductToUpdate?.orderProduct.discountValue,
    //             orderWindow?.id
    //         );

    //         if (
    //             existingCartProductHash == updatedCartProductHash &&
    //             !existingBundleIsComplete &&
    //             updatedBundleIsComplete
    //         ) {
    //             hasUpdatedCartProduct = true;
    //             return cartProductToUpdate;
    //         } else {
    //             return existingCartProduct;
    //         }
    //     });

    //     if (hasUpdatedCartProduct) {
    //         return updatedCartProducts;
    //     } else {
    //         return orderWindow?.cartProducts?.map(cartProduct => {
    //             if (cartProductToUpdate.id === cartProduct.id) {
    //                 return cartProductToUpdate;
    //             }
    //             return cartProduct;
    //         });
    //         // CASE 3: no matching id or quantity found, adding a new bundle to the cart
    //         // return this.handleAddNewCartProductecartProductToUpdate, orderWindow);
    //         return updatedCartProducts;
    //     }
    // };

    handleAddNewCartProduct = (cartProduct: CartProduct, orderWindow: OrderWindow) => {
        return [cartProduct].concat(orderWindow?.cartProducts);
    };

    handleAddNewCartProducts = (cartProducts: CartProduct[], orderWindow: OrderWindow) => {
        return cartProducts.concat(orderWindow?.cartProducts);
    };
}
